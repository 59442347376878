<template>
  <component :is="partData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="partData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('AppPart.fetchError') }}
      </h4>
      <div class="alert-body">
        {{ $t('AppPart.ErrorFound') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-parts-list'}"
        >
          {{ $t('AppPart.partList') }}
        </b-link>
        {{ $t('AppPart.otherParts') }}
      </div>
    </b-alert>

    <b-tabs
      v-if="partData"
      pills
    >
      <!-- Tab: Part -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('AppPart.part') }}</span>
        </template>
        <part-edit-tab-Part
          :part-data="partData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="BookOpenIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('AppPart.logbook') }}</span>
        </template>
        <part-edit-tab-log
          :part-id="partData.parteId"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import partStoreModule from '../partStoreModule'
import PartEditTabPart from './PartEditTabPart.vue'
import PartEditTabLog from './PartEditTabLog.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    PartEditTabPart,
    PartEditTabLog,
  },
  setup() {
    const partData = ref(null)

    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-part'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, partStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app/updateFracciones')

    store.dispatch('app-part/fetchPart', { parteId: router.currentRoute.params.parteId })
      .then(response => { partData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          partData.value = undefined
        }
      })

    return {
      partData,
    }
  },
}
</script>

<style>

</style>
