<template>
  <div>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- User Info: Input Fields -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- Customer-->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('AppPart.columns.customer')"
              rules="required"
            >
              <b-form-group
                :label="$t('AppPart.columns.customer')"
                :state="errors.length > 0 ? false:null"
                label-for="customer"
              >
                <v-select
                  id="customer"
                  v-model="parteData.clienteId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :options="customers"
                  :reduce="option => option.id"
                  disabled
                  @close="changeCustomer(parteData.clienteId)"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Key-->
          <b-col
            cols="12"
            md="4"
          >
            <form-input
              v-model="parteData.clave"
              :label="$t('AppPart.columns.key')"
              validation-rules="required|max:50"
              :get-validation-state="getValidationState"
              disabled
            />
          </b-col>
          <!-- Description -->
          <b-col
            cols="12"
            md="4"
          >
            <form-input
              v-model="parteData.descripcion"
              :label="$t('AppPart.columns.description')"
              validation-rules="required|max:250"
              :get-validation-state="getValidationState"
            />
          </b-col>
          <!-- Weight -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('AppPart.columns.weight')"
              label-for="weight"
            >
              <b-form-input
                id="weight"
                v-model="parteData.peso"
                type="number"
                trim
              />
            </b-form-group>
          </b-col>
          <!-- Fraccion Arancelaria-->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('AppPart.columns.fraccion')"
              rules="required"
            >
              <b-form-group
                :label="$t('AppPart.columns.fraccion')"
                :state="errors.length > 0 ? false:null"
                label-for="fraccion-arancelaria"
              >
                <v-select
                  id="fraccion-arancelaria"
                  v-model="parteData.fraccionArancelariaId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :options="$store.getters['app/fraccionesArancelarias']"
                  :reduce="option => option.id"
                  @close="changeFraccion(parteData.fraccionArancelariaId)"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Fraccion Nicos-->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('AppPart.columns.nico')"
              rules="required"
            >
              <b-form-group
                :label="$t('AppPart.columns.nico')"
                :state="errors.length > 0 ? false:null"
                label-for="nico"
              >
                <v-select
                  id="nico"
                  v-model="parteData.nicoId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :options="nicos"
                  :reduce="option => option.id"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Unidad de medida-->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('AppPart.columns.unitMeasure')"
              label-for="unidad-medida"
            >
              <v-select
                id="unidad-medida"
                v-model="parteData.unidadMedidaId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="texto"
                :options="unidadesMedida"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>
          <!-- Proveedor-->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('AppPart.columns.vendor')"
              label-for="vendor"
            >
              <v-select
                id="vendor"
                v-model="parteData.proveedorId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="texto"
                :options="vendors"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>
          <!-- Tipo de operacion-->
          <b-col
            v-if="!userIsInRole('ClasificadorExterno')"
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('AppPart.columns.operationType')"
              label-for="operationType"
            >
              <v-select
                id="operationType"
                v-model="parteData.operacionTipoId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="texto"
                :options="operationTypes"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>
          <!-- Clave documento -->
          <b-col
            v-if="!userIsInRole('ClasificadorExterno')"
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('AppPart.columns.documentType')"
              label-for="documentType"
            >
              <v-select
                id="documentType"
                v-model="parteData.documentoClaveId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="texto"
                :options="documentTypes"
                :reduce="option => option.id"
                @close="changeOperationType(parteData.operacionTipoId)"
              />
            </b-form-group>
          </b-col>
          <!-- Material type -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('AppPart.columns.materialType')"
              label-for="materialType"
            >
              <v-select
                id="materialType"
                v-model="parteData.parteMaterialTipoId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="texto"
                :options="materialTypes"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          {{ $t('Lists.saveChanges') }}
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('Lists.reset') }}
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import FormInput from '@/components/FormInput.vue'
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, alphaNum, email, min, max,
} from '@validations'
import { ref } from '@vue/composition-api'
import partService from '@/services/part.service'
import optionService from '@/services/option.service'
import common from '@/libs/common'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,

    vSelect,
    FormInput,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    partData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      min,
      max,
    }
  },
  setup(props) {
    /* Servicios */
    const {
      updatePart,
    } = partService()
    const parteData = ref(JSON.parse(JSON.stringify(props.partData)))

    const resetparteData = () => {
      parteData.value = JSON.parse(JSON.stringify(props.partData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetparteData)

    const onSubmit = () => {
      updatePart(parteData.value)
        .then(() => {
          router.push({ name: 'apps-parts-list' })
        })
    }
    const {
      fetchCustomers,
      fetchVendors,
      fetchNicos,
      fetchOperationTypes,
      fetchUnidadesMedida,
      fetchDocumentTypes,
      fetchPartMaterialTypes,
    } = optionService()
    const { userIsInRole } = common()

    const customers = ref([])
    fetchCustomers(data => { customers.value = data })

    const vendors = ref([]) // load on demand
    const nicos = ref([]) // load on demand
    const operationTypes = ref([])
    const documentTypes = ref([])

    fetchOperationTypes(data => { operationTypes.value = data })

    const unidadesMedida = ref([])
    fetchUnidadesMedida(data => { unidadesMedida.value = data })

    const materialTypes = ref([])
    fetchPartMaterialTypes(data => {
      materialTypes.value = data
    })
    const changeFraccion = fraccionArancelariaId => {
      if (fraccionArancelariaId) {
        fetchNicos(data => { nicos.value = data }, fraccionArancelariaId)
      }
    }

    const changeCustomer = clienteId => {
      if (clienteId) {
        fetchVendors(data => { vendors.value = data }, clienteId)
      }
    }

    const changeOperationType = operacionTipoId => {
      if (operacionTipoId) {
        fetchDocumentTypes(operacionTipoId, data => {
          documentTypes.value = data
        })
      }
    }
    changeCustomer(props.partData.clienteId)
    changeFraccion(props.partData.fraccionArancelariaId)
    changeOperationType(props.partData.operacionTipoId)

    return {
      // Services
      updatePart,

      customers,
      vendors,
      nicos,
      operationTypes,
      unidadesMedida,
      documentTypes,
      materialTypes,
      onSubmit,
      parteData,

      // Validation
      refFormObserver,
      getValidationState,
      resetForm,

      changeFraccion,
      changeCustomer,
      changeOperationType,
      userIsInRole,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
